import React, { useState, useEffect, useRef } from 'react';
import styles from './Home.module.css';
import ReactPlayer from 'react-player';
import { Link } from "react-router-dom";
import NewsCarousel from './NewsCarousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';




const Home = () => {

  

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className={styles.home}>
      <div className={styles.header}>
        LATEST
      </div>
       <div className={styles.featured}>
        <div className={styles.DampJanuary}>
          <a
              href="https://medium.com/prxofficial/live-wire-radio-announces-damp-january-with-luke-burbank-a-new-special-podcast-series-ff536397dcb5"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
            <img
              src={"https://benjamincleek.s3.us-west-2.amazonaws.com/photos/473de540-baa5-45d2-b1f2-4fa96c28b973-Screen%20Shot%202024-12-29%20at%205.56.14%20PM.png"} 
              alt='Damp January'
              className={styles.image}
            />
            <div className={styles.albumOverlay}>
              <h2 className={styles.albumTitle}>Damp January</h2>
              <p className={styles.albumDescription}>
               Original Music by Benjamin Cleek
              </p>
            </div>

          </a>
        </div> 
        <div className={styles.Spotify}>
          <iframe src="https://open.spotify.com/embed/episode/2394KDHt8wJyqFZmQdcWoL?utm_source=generator" width="200%" height="300" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
        </div>  
      </div>

      <div className={styles.Albums}>
        <div className={styles.featuredAlbums}>
          <a 
            href="https://open.spotify.com/album/3lWaKsq5qdMZfdeibLqpBl?si=81VFBeohSfm3LvKf086L8g" 
            target="_blank" 
            rel="noopener noreferrer"
            className={styles.albumLink}
          >
            <img 
              src="https://benjamincleek.s3.us-west-2.amazonaws.com/photos/108a133b-ed00-4a9d-863a-883cae591b45-Taylor%20Appelo%20-%20Animal.png" 
              alt="Taylor Appelo - Animal" 
              className={styles.albumImage}
            />
            <div className={styles.albumOverlay}>
              <h2 className={styles.albumTitle}>Taylor Appelo - Animal</h2>
              <p className={styles.albumDescription}>
                Production by Benjamin Cleek
              </p>
            </div>
          </a>
        </div>
        <div className={styles.featuredAlbums}>
          <a 
            href="https://open.spotify.com/album/4nae493gDwLmnzgFHOfCm5?si=UeRxgAUESQazwA4z3D2QXA" 
            target="_blank" 
            rel="noopener noreferrer"
            className={styles.albumLink}
          >
            <img 
              src="https://benjamincleek.s3.us-west-2.amazonaws.com/photos/e7adb590-e6ab-407a-afee-484393cf7037-Illya%20-%20Midnight%20Catches%202025%20Screen%20Shot%202025-02-01%20at%205.44.12%20PM.png" 
              alt="Illya - Midnight Catches 2025" 
              className={styles.albumImage}
            />
            <div className={styles.albumOverlay}>
              <h2 className={styles.albumTitle}>Illya - Midnight Catches 2025</h2>
              <p className={styles.albumDescription}>
                Production by Benjamin Cleek
              </p>
            </div>
          </a>
        </div>
      </div>
      
      <div className={styles.header}>
        NEWS
      </div>
     
      <div className={styles.carouselWrapper}>
        <NewsCarousel />
      </div>

      <div className={styles.header}>
        RECENT WORKS
      </div>
      <div className={styles.newsCard}>
          <div className={styles.lilly}>
          <Link to="/lilly">
            <img 
              src="https://benjamincleek.s3.us-west-2.amazonaws.com/photos/9aef310f-24fb-4c50-9909-2e46ea148de0-LILLY-LIVES-ALONE.jpg" 
              alt="Poster for 'Lilly Lives Alone' written and directed by Martin Melnick" 
            />
          </Link>

          </div>

    
          <div className={styles.description}>
            <div className={styles.title}>
              Lilly Lives Alone
            </div>
            <div>
              The ten year anniversary of a tragic accident sends Lilly's guilt-ridden mind over the edge, plunging her into a drug and booze fueled fever dream with supernatural terrors.
            </div>
            <br/>
            <div>
              Written and Directed by Martin Melnick
            </div>
            <br/>
            <div>
              Music by Benjamin Cleek
            </div>
          </div>
      </div>
        <div className={styles.newsCard}>
          <div className={styles.spring}>
            <img
              src="https://benjamincleek.s3.us-west-2.amazonaws.com/photos/affe197d-fed1-4020-ad28-6fd22a6b9383-spring%20-%20ai%20upscaled%20enhanced.jpg"
              alt="Preview thumbnail for 'Spring' video"
              className={styles.thumbnail}
              onClick={openModal}
            />
          </div>
          <div className={styles.description}>
              <div className={styles.title}>
                Spring             
              </div>
              <div>
               
              </div>
                <br/>
              <div>
                
              </div>
                <br/>
              <div>
                Music by Benjamin Cleek
              </div>
            </div>
          {isModalOpen && (
          <div className={styles.modalOverlay} onClick={closeModal}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
              <ReactPlayer
                url="https://www.youtube.com/watch?v=732w9e0MXzA"
                playing
                controls={false}
                width="100%"
                height="100%"
                config={{
                  youtube: {
                    playerVars: {
                      controls: 0, 
                      modestbranding: 1,
                      rel: 0, 
                      showinfo: 0, 
                      iv_load_policy: 3, 
                    },
                  },
                }}
                className={styles.reactPlayer}
              />
            </div>
          </div>
        )}
        </div>

      <div className={styles.newsCard}>
        <div className={styles.stills}>
          <Link to="/jollyriot">
            <img src={"https://benjamincleek.s3.us-west-2.amazonaws.com/photos/bc95522f-a6f4-4597-9e2f-6be584b83496-Jolly%20Riot%20Poster%20-%20maxresdefault.jpg"} 
              alt="Poster for 'The Jolly Riot' featuring rock and heavy metal pirate themes" 
            /> 
          </Link>
        </div> 
        <div className={styles.description}>
          <div className={styles.title}>
            The Jolly Riot
          </div>
          <div>
            Original musical - Part "Pirates of the Caribbean" and part "Treasure Island" with heavy metal sea shanties, epic battles, rollicking choreography and a colorful cast of characters, The Jolly Riot has everything: rum, sea monsters, mermaids, sword fights, rum, bad pirate accents and more rum.
          </div>
          <br/>
          <div>
            Lyrics by William Thomas Berk and Heidi Davis
          </div>
          <br/>
          <div>
            Music by Ken Bussell and Heidi Davis
          </div>
          <br/>
          <div>
            Transcriptions by Benjamin Cleek
          </div>
        </div>
      </div>

  
        <div className={styles.newsCard}>
            <div className={styles.stills}>
              <Link to="/tfaaf">
                <img src={"https://benjamincleek.s3.us-west-2.amazonaws.com/photos/b66d7765-2be4-45dc-8655-455be8127bfc-Screen%20Shot%202024-03-02%20at%2010.07.11%20AM.png"} 
                  alt="Poster for 'Two Funerals and a Freezer' " 
                /> 
              </Link>
            </div> 
            <div className={styles.description}>
              <div className={styles.title}>
                Two Funerals and a Freezer              
              </div>
              <div>

              </div>
                <br/>
              <div>
                
              </div>
                <br/>
              <div>
                Directed by Melissa Fisher
              </div>
                <br/>
              <div>
                Music by Benjamin Cleek
              </div>
            </div>
        </div>
       
        {/* <div className={styles.helloGoodbye}>
          <ReactPlayer
            url="https://www.youtube.com/watch?v=J0IuKPfL3Ag"
            playing
            light={true}
          />
        </div> */}
      </div>

        
  );
};

export default Home;
