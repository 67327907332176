import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './Music.module.css'; 
import AlbumPage from './AlbumPage.js';
import AlbumAbout from './AlbumAbout.js';
import { API_ENDPOINT } from '../apiEndpoints.js';
import { Link } from 'react-router-dom';

const OriginalMusic = () => {
  const [albums, setAlbums] = useState([]);
  const [albumid, setAlbumid] = useState(null);
  const [hoveredAlbum, setHoveredAlbum] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const artistid = 3;

    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/albums/artistid/${artistid}`);
        setAlbums(response.data);
      } catch (error) {
        console.error("Error fetching albums:", error);
        console.log("Full error response:", error.response);
        console.log("API_ENDPOINT:", API_ENDPOINT);
      }
    };

    fetchData();

  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  const handleAlbumClick = (albumid) => {
    console.log('Clicked albumid:', albumid);
    setAlbumid(albumid);
    setHoveredAlbum(null);
  };
  
  const handleAlbumHover = (albumid) => {
    setHoveredAlbum(albumid);
  };

  const handleAlbumLeave = () => {
    setHoveredAlbum(null);
  };

  
  const hardcodedAlbumIds = [ 229, 230, 241, 238, 237 ];

  return (
    <div>
     <div className={styles.Music}>
      <div className={styles.albumList}>
        {hardcodedAlbumIds.map(hardcodedId => {
          const album = albums.find(a => a.id === hardcodedId);

          if (!album) {
            return null;
          }

          return (
            <div
              key={album.id}
              className={styles.horizontalAlbumCard}
              onMouseEnter={() => handleAlbumHover(album.id)}
              onMouseLeave={handleAlbumLeave}
              onClick={() => handleAlbumClick(album.id)}
            >
              <Link to={`/AlbumPage/${album.id}`} style={{ textDecoration: 'none' }}>
                <div className={styles.albums}>
                  <img
                    src={album.fileurl}
                    alt={album.albumname}
                    className={`${styles.albumListImg} ${hoveredAlbum === album.id ? styles.hoveredAlbum : ''}`}
                    onMouseEnter={() => handleAlbumHover(album.id)}
                    onMouseLeave={handleAlbumLeave}
                  />
                  <div className={styles.about}>
                    <AlbumAbout albumid={album.id} />
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
      <div className={styles.player}>
        {albumid && <AlbumPage albumid={albumid} />}
      </div>
      <div>
        <iframe 
          title="Bandcamp Embed"
          style={{ 
            border: 'none',  
            width: '50vw', 
            height: '120px',
            backgroundColor: '#000000',
          }}
          src="https://bandcamp.com/EmbeddedPlayer/album=3325195107/size=medium/bgcol=000000/linkcol=ffffff/tracklist=true/"
          seamless
        >
          <a href="https://vyvyv.bandcamp.com/album/dreams-of-venus">Dreams of Venus by VYVYV</a>
        </iframe>
      </div>
    </div>
    </div>
  );
};

export default OriginalMusic;
