import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './ProducedBy.module.css'; 
import AlbumPage from './AlbumPage.js';
import AlbumAbout from './AlbumAbout.js';
import { API_ENDPOINT } from '../apiEndpoints.js';
import { Link } from 'react-router-dom';

const Music = () => {
  const [albums, setAlbums] = useState([]);
  const [albumid, setAlbumid] = useState(null);
  const [hoveredAlbum, setHoveredAlbum] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const artistid = 4;

    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/albums/artistid/${artistid}`);
        setAlbums(response.data);
      } catch (error) {
        console.error("Error fetching albums:", error);
        console.log("Full error response:", error.response);
        console.log("API_ENDPOINT:", API_ENDPOINT);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  const handleAlbumClick = (albumid) => {
    console.log('Clicked albumid:', albumid);
    setAlbumid(albumid);
    setHoveredAlbum(null);
  };
  
  const handleAlbumHover = (albumid) => {
    setHoveredAlbum(albumid);
  };

  const handleAlbumLeave = () => {
    setHoveredAlbum(null);
  };

  
  const hardcodedAlbumIds = [ 231, 234 ];

  return (
    <div className={styles.producedBy}>
      <div className={styles.header}>
      </div>
      <div className={styles.Albums}>
        <div className={styles.featuredAlbums}>
          <a 
            href="https://open.spotify.com/album/3lWaKsq5qdMZfdeibLqpBl?si=81VFBeohSfm3LvKf086L8g" 
            target="_blank" 
            rel="noopener noreferrer"
            className={styles.albumLink}
          >
            <img 
              src="https://benjamincleek.s3.us-west-2.amazonaws.com/photos/108a133b-ed00-4a9d-863a-883cae591b45-Taylor%20Appelo%20-%20Animal.png" 
              alt="Taylor Appelo - Animal" 
              className={styles.albumImage}
            />
            <div className={styles.albumOverlay}>
              <h2 className={styles.albumTitle}>Taylor Appelo - Animal</h2>
              <p className={styles.albumDescription}>
                Description of the album goes here
              </p>
            </div>
          </a>
        </div>
        <div className={styles.featuredAlbums}>
          <a 
            href="https://open.spotify.com/album/4nae493gDwLmnzgFHOfCm5?si=UeRxgAUESQazwA4z3D2QXA" 
            target="_blank" 
            rel="noopener noreferrer"
            className={styles.albumLink}
          >
            <img 
              src="https://benjamincleek.s3.us-west-2.amazonaws.com/photos/e7adb590-e6ab-407a-afee-484393cf7037-Illya%20-%20Midnight%20Catches%202025%20Screen%20Shot%202025-02-01%20at%205.44.12%20PM.png" 
              alt="Illya - Midnight Catches 2025" 
              className={styles.albumImage}
            />
            <div className={styles.albumOverlay}>
              <h2 className={styles.albumTitle}>Illya deTorres - Midnight Catches</h2>
              <p className={styles.albumDescription}>
                Description of the album goes here
              </p>
            </div>
          </a>
        </div>
        <div className={styles.featuredAlbums}>
          <a 
            href="https://open.spotify.com/album/69oQKwLh98k7Sm2QhNEOUO?si=c5OqsuafQIG5RWi9BUUhFQ" 
            target="_blank" 
            rel="noopener noreferrer"
            className={styles.albumLink}
          >
            <img 
              src="https://benjamincleek.s3.us-west-2.amazonaws.com/photos/eeda35d9-62ac-45ab-bc99-4117adb048cb-Melissa%20Dollar%20-%20Weather%20The%20Storm.jpg" 
              alt="Melissa Dollar - Weather The Storms" 
              className={styles.albumImage}
            />
            <div className={styles.albumOverlay}>
              <h2 className={styles.albumTitle}>Melissa Dollar - Weather The Storms</h2>
              <p className={styles.albumDescription}>
                Description of the album goes here
              </p>
            </div>
          </a>
        </div>
        <div className={styles.featuredAlbums}>
          <a 
            href="https://open.spotify.com/album/1UyI9mHSPdfpanT5D0boDJ?si=yyn1kNu_SCOcOFVBK7XUlQ" 
            target="_blank" 
            rel="noopener noreferrer"
            className={styles.albumLink}
          >
            <img 
              src="https://benjamincleek.s3.us-west-2.amazonaws.com/photos/5d603355-c3d4-4f02-996a-265041ffab37-American%20Harmoney%20-%20David%20Van%20Auken.jpg" 
              alt="David Van Auken - American Harmony" 
              className={styles.albumImage}
            />
            <div className={styles.albumOverlay}>
              <h2 className={styles.albumTitle}>David Van Auken - American Harmony</h2>
              <p className={styles.albumDescription}>
                Description of the album goes here
              </p>
            </div>
          </a>
        </div>
        <div className={styles.featuredAlbums}>
          <a 
            href="https://open.spotify.com/artist/32KAHfPyZwfR8CNw48TNws"
            target="_blank" 
            rel="noopener noreferrer"
            className={styles.albumLink}
          >
            <img 
              src="https://benjamincleek.s3.us-west-2.amazonaws.com/photos/70ba801b-40a2-4654-ad7e-c20a2724d93e-Blakie%20Boy.png" // You'll add the URL here
              alt="Artist Profile" 
              className={styles.albumImage}
            />
            <div className={styles.albumOverlay}>
              <h2 className={styles.albumTitle}>Blakey Boy - Dumkutwaf</h2>
              <p className={styles.albumDescription}>
                Listen on Spotify
              </p>
            </div>
          </a>
          </div>
          <div className={styles.featuredAlbums}>
            <a 
              href="https://open.spotify.com/album/78m8UTCOjvzLFhUNgSkibv"
              target="_blank" 
              rel="noopener noreferrer"
              className={styles.albumLink}
            >
              <img 
                src="https://benjamincleek.s3.us-west-2.amazonaws.com/photos/54304fd4-1c83-4cf0-ace6-5d6d7c7924a8-francisco%20the%20man.jpg" // You'll add the URL here
                alt="Album" 
                className={styles.albumImage}
              />
              <div className={styles.albumOverlay}>
                <h2 className={styles.albumTitle}>Zach Orr - Francisco The Man</h2>
                <p className={styles.albumDescription}>
                  Listen on Spotify
                </p>
              </div>
            </a>
          </div>
          <div className={styles.featuredAlbums}>
            <a 
              href=" https://open.spotify.com/album/3upmkHrUFa7eYRKMywGJMl?si=QoHSyelxSR-Fus7HHfzeng"
              target="_blank" 
              rel="noopener noreferrer"
              className={styles.albumLink}
            >
              <img 
                src="https://benjamincleek.s3.us-west-2.amazonaws.com/photos/b82de2de-68fb-47f0-bf85-dcf09c581807-Removed.png" // You'll add the URL here
                alt="Album" 
                className={styles.albumImage}
              />
              <div className={styles.albumOverlay}>
                <h2 className={styles.albumTitle}>Benjamin Cleek - Removed</h2>
                <p className={styles.albumDescription}>
                  Listen on Spotify
                </p>
              </div>
            </a>
          </div>
          <div className={styles.featuredAlbums}>
            <a 
              href="https://open.spotify.com/album/5x9RZtFiBZKBVED1gbyvvO?si=GdgCWgzmRYe6UR4c5MiO0Q"
              target="_blank" 
              rel="noopener noreferrer"
              className={styles.albumLink}
            >
              <img 
                src="https://benjamincleek.s3.us-west-2.amazonaws.com/photos/48da9504-f09d-4f23-b490-d643a8c5ee4b-Screen%20Shot%202025-02-26%20at%205.40.54%20PM.png" // You'll add the URL here
                alt="Album" 
                className={styles.albumImage}
              />
              <div className={styles.albumOverlay}>
                <h2 className={styles.albumTitle}>Walkfast - Rendition</h2>
                <p className={styles.albumDescription}>
                  Listen on Spotify
                </p>
              </div>
            </a>
          </div>
          <div className={styles.featuredAlbums}>
            <a 
              href="https://vyvyv.bandcamp.com/album/dreams-of-venus"
              target="_blank" 
              rel="noopener noreferrer"
              className={styles.albumLink}
            >
              <img 
                src="https://benjamincleek.s3.us-west-2.amazonaws.com/photos/3a69f916-2b5c-4f55-bec6-33a8fcdb81e3-vyvyv%20-%20what%20dreams%20may%20cme.png" // You'll add the URL here
                alt="Album" 
                className={styles.albumImage}
              />
              <div className={styles.albumOverlay}>
                <h2 className={styles.albumTitle}>VYVYV - Dreams Of Venus</h2>
                <p className={styles.albumDescription}>
                  Listen on Bandcamp
                </p>
              </div>
            </a>
          </div>
          <div className={styles.featuredAlbums}>
            <a 
              href="https://open.spotify.com/album/2aGR6um2XgkW6CLchQ0MCQ?si=7FPu7rCMT4SbqQPNi06aCQ"
              target="_blank" 
              rel="noopener noreferrer"
              className={styles.albumLink}
            >
              <img 
                src="https://benjamincleek.s3.us-west-2.amazonaws.com/photos/58ddb852-7cd5-46a5-93fe-f72ff5ff3328-Screen%20Shot%202025-02-26%20at%205.40.15%20PM.png" // You'll add the URL here
                alt="Album" 
                className={styles.albumImage}
              />
              <div className={styles.albumOverlay}>
                <h2 className={styles.albumTitle}>Walkfast - Walkfast</h2>
                <p className={styles.albumDescription}>
                  Listen on Bandcamp
                </p>
              </div>
            </a>
          </div>
          <div className={styles.featuredAlbums}>
            <a 
              href="https://open.spotify.com/album/6Pkoq8IXgLAdNEkQ32fnx8?si=Px5lWBqeScGKqbzjumsp0g"
              target="_blank" 
              rel="noopener noreferrer"
              className={styles.albumLink}
            >
              <img 
                src="https://benjamincleek.s3.us-west-2.amazonaws.com/photos/8a4fc3b2-d8dd-4e67-bc06-8e2d64a7a79a-The%20Molting%20-%20Babel%20Echo.png" // You'll add the URL here
                alt="Album" 
                className={styles.albumImage}
              />
              <div className={styles.albumOverlay}>
                <h2 className={styles.albumTitle}>The Molting - Babel Echo</h2>
                <p className={styles.albumDescription}>
                  Listen on Bandcamp
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
  );
};

export default Music;
