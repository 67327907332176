import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LuMenu } from 'react-icons/lu';
import styles from './Navbar.module.css';

const AppName = () => (
  <div className={styles.appName}>
    <Link to="/" className={styles.navLink}>
      BENJAMIN CLEEK
    </Link>
  </div>
);

const Description = () => (
  <div className={styles.description}>
  </div>
);

function Navbar() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [showMobileMenuIcon, setShowMobileMenuIcon] = useState(window.innerWidth <= 768);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleResize = () => {
    setShowMobileMenuIcon(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className={styles.Navbar}>
      <AppName />
      <Description />
      {showMobileMenuIcon && (
        <div className={styles.mobileMenuIcon} onClick={toggleMenu}>
          <LuMenu />
        </div>
      )}
      {(!showMobileMenuIcon || isMenuOpen) && (
        <ul className={`${styles.navbarNav} ${isMenuOpen ? styles.showMenu : ''}`}>
          <li className={styles.navbarItem} onClick={closeMenu}>
            <Link to="/Bio" className={styles.navLink}>
              Bio
            </Link>
          </li>
          <li className={styles.navbarItem} onClick={closeMenu}>
            <Link to="/Film/filmscores" className={styles.navLink}>
              Film
            </Link>
          </li>
          <li className={styles.navbarItem} onClick={closeMenu}>
            <Link to="/music" className={styles.navLink}>
              Music
            </Link>
          </li>
          <li className={styles.navbarItem} onClick={closeMenu}>
            <Link to="/commercial" className={styles.navLink}>
              Commercial
            </Link>
          </li>
          <li className={styles.navbarItem} onClick={closeMenu}>
            <Link to="/Collaborations" className={styles.navLink}>
              Collaborations
            </Link>
          </li>
          <li className={styles.navbarItem} onClick={closeMenu}>
            <Link to="/contact" className={styles.navLink}>
              Contact
            </Link>
          </li>
          {/* <li className={styles.navbarItem} onClick={closeMenu}>
            <Link to="/devportfolio" className={styles.navLink}>
              dev portfolio
            </Link>
          </li> */}
 
          {/* <li className={styles.navbarItem} onClick={closeMenu}>
            <Link to="/Admin" className={styles.navLink}>
              Admin
            </Link>
          </li> */}
        </ul>
      )}
    </nav>
  );
}

export default Navbar;
