import React, { useState, useEffect, useRef } from 'react';
import styles from './FilmScores.module.css';
import ReactPlayer from 'react-player';
import { v4 as uuidv4 } from 'uuid';
import { IoCloseOutline } from "react-icons/io5";

const videos = [
  {
    id: 1,
    title: 'Two Funerals',
    pageLink: '/tfaaf',
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/876dd415-e840-4396-a407-7a59fe7972a7-TFAAF%20-%20Two%20Funerals%20poster%20-%20smaller.jpg',
    director: 'Jane Smith',
    musicBy: 'Benjamin Cleek',
    description: 'An emotional narrative about loss and coming to terms with death.'
  },
  {
    id: 3,
    title: 'The Mad Ones',
    videoUrl: 'https://vimeo.com/685250353', 
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/3e1f11f2-fd80-4522-88c2-16a36eb12e6b-The%20Mad%20Ones.jpg',
  },

  {
    id: 2,
    title: 'Common Threads',
    videoUrl: 'https://vimeo.com/14141165', 
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/6a213956-0055-407a-a09d-171ef569344d-Common%20Threads.jpg',
  },
  {
    id: 13,
    title: 'Alma',
    pageLink: '/alma',
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/8c352e07-0ee8-46b4-ab1b-6296a72d539f-Screen%20Shot%202024-08-03%20at%202.05.03%20PM.png',
  },
  {
    id: 5,
    title: 'Spring',
    videoUrl: 'https://www.youtube.com/watch?v=732w9e0MXzA&t=11s', 
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/1998ce28-1e24-40c4-a990-b8e6602664d7-Spring%20-%20awarded.jpg',  
    director: 'John Doe',
    musicBy: 'Benjamin Cleek',
    description: 'A beautiful exploration of renewal and growth.'
  },
  {
    id: 7,
    title: 'Evolution of Evil',
    videoUrl: 'https://www.youtube.com/watch?v=-CXvgPWSKdc', 
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/75f81d2a-56f8-4bbf-b9f2-d17ed206275d-Evolution%20of%20Evil%20%281%29.jpg',
  },
  {
    id: 11,
    title: 'Sliding Scale',
    videoUrl: 'https://www.youtube.com/watch?v=-YEDn6mSKJg', 
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/8b168917-1d1f-49c4-b9e9-e57b937c1f24-Sliding%20Scale%20thumb.jpg',
  },
  {
    id: 6,
    title: 'House of Greeting Cards',
    videoUrl: 'https://vimeo.com/130605743', 
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/c73f22db-51ab-4ec4-9661-9ac50c3537f3-House%20Of%20Greeting%20Cards-fotor-20240215103921.jpg',
  },
  {
    id: 8,
    title: 'ECT',
    videoUrl: 'https://www.youtube.com/watch?v=9iuB0r2UQvg', 
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/938ab1d1-e8a8-4ee5-81ce-e59f3c814396-ECT%20poster%20square.jpg',
  },  ];

  const VideoCard = ({ video, onClick }) => (
    <div 
      className={styles.videoCard} 
      onClick={(event) => onClick(video, event)}
    >
      <div className={styles.imageWrapper}>
        <img className={styles.videoImg} src={video.thumbnail} alt={video.title} />
        <div className={styles.infoCard}>
          <h3>{video.title}</h3>
        </div>
      </div>
    </div>
  );

  const Composer = () => {
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [isPlayerVisible, setPlayerVisible] = useState(false);
    const playerRef = useRef(null);
  
    const handleVideoClick = (video, event) => {
      if (video.pageLink) {
        window.open(video.pageLink, '_blank');
      } else {
        if (!selectedVideo || selectedVideo.id !== video.id) {
          setSelectedVideo(video);
          setPlayerVisible(true);
        } else {
          setSelectedVideo(null);
          setPlayerVisible(false);
        }
        event.stopPropagation();
      }
    };
  
    const handleCloseModal = () => {
      setPlayerVisible(false);
      setSelectedVideo(null);
    };
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          playerRef.current &&
          !playerRef.current.contains(event.target) &&
          event.target.className !== styles.button &&
          event.target.closest(`.${styles.modalContent}`) === null
        ) {
          handleCloseModal();
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [playerRef]);
  
    return (
      <div className={styles.filmScores}>
        <div className={styles.videoThumbs}>
          {videos.map((video) => (
            <VideoCard key={uuidv4()} video={video} onClick={handleVideoClick} />
          ))}
          {selectedVideo && (
            <div className={styles.modal}>
              <div className={styles.modalContent} ref={playerRef}>
                {!selectedVideo.pageLink && (
                  <ReactPlayer
                    url={selectedVideo.videoUrl}
                    width="100%"
                    height="100%"
                    style={{ position: 'absolute', top: 0, left: 0 }}
                    controls
                    playing
                  />
                )}
                <button onClick={handleCloseModal} className={styles.button}>
                  <IoCloseOutline />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  
  export default Composer;