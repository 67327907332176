import React, { useState, useEffect } from 'react';
import { Link, Route, Routes, useMatch, useLocation } from 'react-router-dom';
import styles from './Music.module.css';
import OriginalMusic from './OriginalMusic';
import ProducedBy from './ProducedBy';

const Music = () => {
  const match = useMatch('/music/*');
  const location = useLocation();
  const [toggleState, setToggleState] = useState(1);
  const [showTabs, setShowTabs] = useState(window.innerWidth > 768);

  const toggleTab = (index) => {
    console.log(`Toggling to tab ${index}`);
    setToggleState(index);
  };

  const handleResize = () => {
    setShowTabs(window.innerWidth > 768);
  };

  useEffect(() => {
    console.log('Toggle State changed:', toggleState);
  }, [toggleState]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const updateToggleState = () => {
      if (location.pathname === '/music' || location.pathname === '/music/') {
        setToggleState(1);
      } else if (location.pathname === '/music/producedby') {
        setToggleState(2);
      }
    };

    updateToggleState();
  }, [location]);

  return (
    <div className={styles.container}>
      {showTabs ? (
        <div className={styles['bloc-tabs']}>
          <Link
            to="."
            className={toggleState === 1 ? `${styles.tabs} ${styles['active-tabs']}` : styles.tabs}
            onClick={() => toggleTab(1)}
          >
            Film Scores
          </Link>
          <Link
            to="producedby"
            className={toggleState === 2 ? `${styles.tabs} ${styles['active-tabs']}` : styles.tabs}
            onClick={() => toggleTab(2)}
          >
            Produced By
          </Link>
        </div>
      ) : (
        <ul className={styles['list-tabs']}>
          <li
            className={toggleState === 1 ? `${styles.listItem} ${styles.activeItem}` : styles.listItem}
            onClick={() => toggleTab(1)}
          >
            <Link to=".">Original Music</Link>
          </li>
          <li
            className={toggleState === 2 ? `${styles.listItem} ${styles.activeItem}` : styles.listItem}
            onClick={() => toggleTab(2)}
          >
            <Link to="producedby">Produced By</Link>
          </li>
        </ul>
      )}
      <div>
        <Routes>
          <Route path="/" element={<OriginalMusic />} />
          <Route path="producedby" element={<ProducedBy />} />
        </Routes>
      </div>
    </div>
  );
};

export default Music;